$(document).ready(function() {
    
    $('#head a.mobileNav').click(function(){
        if($('.dropdown').hasClass('active')){
            $('a.mobileNav').removeClass('active');
            $('a.mobileNav').find('i').addClass('icon-menu').removeClass('icon-close');
            $('.dropdown').removeClass('showed');
            $('a.mobileNav').find('span').html($('a.mobileNav').attr('data-menu'));
            setTimeout(
                function(){
                    $('.dropdown').removeClass('active');
                }, 100);
            setTimeout(
                function(){
                    $('#head').removeClass('open');
            }, 800);
        }else{
            $('#head').addClass('open');
            $('.dropdown').addClass('active');
            $('a.mobileNav').addClass('active');
            $('a.mobileNav').find('i').removeClass('icon-menu').addClass('icon-close');
            $('a.mobileNav').find('span').html($('a.mobileNav').attr('data-close'));
            setTimeout(
                function(){
                    $('.dropdown').addClass('showed');
                }, 100);
        }
    });

    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    var owlProduct = $('.owl-product');
    owlProduct.owlCarousel({
        loop: false,
        margin: 30,
        nav: true,
        dots: true,
        navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
        items: 4,
        slideBy: 3,
        autoplay:false,
        stagePadding:15,
        responsive : {
            0 : {
                items: 1
            },
            504 : {
                items: 2
            },
            880 : {
                items: 3
            },
            1100 : {
                items: 4
            }
        }
    });

    renderMaps();
});

function renderMaps(){
    if($( "body" ).has( "#map" )){
        var LeafIcon = L.Icon.extend({
            options: {
                iconSize:     [75, 100],
                iconAnchor:   [37, 100]
            }
        });
        var icons  = new LeafIcon({iconUrl: url+'/img/map.svg'});

        zoom = 10;
        if($('#map').attr('data-zoom') != '')  zoom = parseInt($('#map').attr('data-zoom'));
        lat  = parseFloat($('#map').attr('data-lat'));
        lng  = parseFloat($('#map').attr('data-lng'));

        var mbAttr = '',
            mbUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoicm9jZXNtYW5pYyIsImEiOiJjam5hb2NzazQyZWxoM2tydWd0OHN5OThkIn0.2kz8ZH1-HH-TkOh1anDHDA';

        var grayscale   = L.tileLayer(mbUrl, {id: 'mapbox/light-v9', attribution: mbAttr});

        var map = L.map('map', {
            center: [lat, lng],
            zoom: zoom,
            layers: [grayscale],
            scrollWheelZoom: false
        });

        L.marker([lat, lng], {icon: icons}).addTo(map);
    }
}
